var render = function render(){
  var _vm$siteInfo;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    style: {
      backgroundColor: _vm.backgroundColor
    },
    attrs: {
      "id": "inspire"
    }
  }, [!_vm.$vuetify.breakpoint.mobile ? _c('v-app-bar', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _vm.onScroll,
        options: {
          threshold: [0, 1.0]
        }
      },
      expression: "{\n            handler: onScroll,\n            options: { threshold: [0, 1.0] },\n        }"
    }],
    staticClass: "header",
    attrs: {
      "app": "",
      "height": "85",
      "absolute": "",
      "elevation": "0",
      "color": "white"
    }
  }, [_c('v-responsive', {
    staticClass: "ma-0",
    attrs: {
      "width": "100vw",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    staticClass: "mx-auto",
    attrs: {
      "width": "100%",
      "max-width": _vm.maxWidth,
      "height": "100%"
    }
  }, [_c('v-layout', {
    staticClass: "fill-height",
    attrs: {
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "shrink": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/ticketmania/ci.png"
    }
  })])], 1), _c('v-spacer'), _c('v-flex', {
    attrs: {
      "shrink": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "slot": "center"
    },
    slot: "center"
  }, [_c('v-btn', {
    attrs: {
      "height": "85",
      "dark": "",
      "text": "",
      "tile": "",
      "x-large": "",
      "color": "black",
      "active-class": "no-active",
      "ripple": false,
      "to": "/gshop/giftcards"
    }
  }, [_vm._v("모바일상품권")]), _c('v-btn', {
    attrs: {
      "height": "85",
      "dark": "",
      "text": "",
      "tile": "",
      "x-large": "",
      "color": "black",
      "active-class": "no-active",
      "ripple": false,
      "to": "/about"
    }
  }, [_vm._v(_vm._s(_vm.siteInfo.serviceNameKo || _vm.siteInfo.projectNameKo) + "소개")]), _c('v-btn', {
    attrs: {
      "height": "85",
      "dark": "",
      "text": "",
      "tile": "",
      "x-large": "",
      "color": "black",
      "active-class": "no-active",
      "ripple": false,
      "to": "/center/faqs"
    }
  }, [_vm._v("자주묻는질문")]), _c('v-btn', {
    attrs: {
      "height": "85",
      "dark": "",
      "text": "",
      "tile": "",
      "x-large": "",
      "color": "black",
      "active-class": "no-active",
      "ripple": false,
      "to": "/center/notifications"
    }
  }, [_vm._v("공지사항")]), _c('v-btn', {
    attrs: {
      "height": "85",
      "dark": "",
      "text": "",
      "tile": "",
      "x-large": "",
      "color": "black",
      "active-class": "no-active",
      "ripple": false,
      "to": "/board/question"
    }
  }, [_vm._v("문의하기")])], 1)], 1), _vm.siteInfo.memberPayable ? [_c('v-spacer'), _vm.logon ? _c('v-flex', {
    attrs: {
      "shrink": ""
    }
  }, [_c('v-layout', {
    staticClass: "pl-4",
    attrs: {
      "slot": "center"
    },
    slot: "center"
  }, [_c('v-btn', {
    attrs: {
      "height": "85",
      "dark": "",
      "text": "",
      "tile": "",
      "color": "primary",
      "active-class": "no-active",
      "ripple": false
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("로그아웃")]), _c('v-btn', {
    attrs: {
      "height": "85",
      "dark": "",
      "text": "",
      "tile": "",
      "color": "primary",
      "active-class": "no-active",
      "ripple": false,
      "to": "/member/gshop/orders"
    }
  }, [_vm._v("마이페이지")])], 1)], 1) : _c('v-flex', {
    attrs: {
      "shrink": ""
    }
  }, [_c('v-layout', {
    staticClass: "pl-4",
    attrs: {
      "slot": "center"
    },
    slot: "center"
  }, [_c('v-btn', {
    attrs: {
      "height": "85",
      "dark": "",
      "text": "",
      "tile": "",
      "color": "primary",
      "active-class": "no-active",
      "ripple": false,
      "to": "/login"
    }
  }, [_vm._v("로그인")]), _c('v-btn', {
    attrs: {
      "height": "85",
      "dark": "",
      "text": "",
      "tile": "",
      "color": "primary",
      "active-class": "no-active",
      "ripple": false,
      "to": "/join"
    }
  }, [_vm._v("회원가입")])], 1)], 1)] : _vm._e()], 2)], 1)], 1)], 1) : _vm._e(), _vm.$vuetify.breakpoint.mobile ? _c('v-app-bar', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _vm.onScroll,
        options: {
          threshold: [0, 1.0]
        }
      },
      expression: "{\n            handler: onScroll,\n            options: { threshold: [0, 1.0] },\n        }"
    }],
    attrs: {
      "height": "60",
      "max-height": "60",
      "color": "white",
      "elevation": "2"
    }
  }, [_c('v-layout', {
    attrs: {
      "wrap": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "100%",
      "height": "60"
    }
  }, [_c('v-layout', {
    attrs: {
      "align-center": "",
      "fill-height": ""
    }
  }, [_c('v-app-bar-nav-icon', {
    on: {
      "click": function ($event) {
        _vm.drawer = true;
      }
    }
  }), _c('v-spacer'), _c('router-link', {
    staticClass: "align-self-center",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    staticStyle: {
      "display": "block"
    },
    attrs: {
      "src": "/images/ticketmania/ci.png",
      "width": "114.8px",
      "height": "21px"
    }
  })]), _c('v-spacer'), _c('v-sheet', {
    attrs: {
      "width": "48"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "fixed": "",
      "temporary": "",
      "height": "auto",
      "width": "100%"
    },
    model: {
      value: _vm.drawer,
      callback: function ($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('v-list', {
    staticClass: "overflow-auto pa-0",
    attrs: {
      "max-height": "100vh"
    }
  }, [_c('v-list-item', {
    staticClass: "primary-green",
    attrs: {
      "dark": ""
    }
  }, [_c('v-list-item-title', [_c('span', [_vm._v(_vm._s(_vm.siteInfo.serviceNameKo || _vm.siteInfo.projectNameKo))])]), _c('v-list-item-action', {
    on: {
      "click": function ($event) {
        _vm.drawer = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-list-item', {
    staticClass: "white",
    attrs: {
      "active-class": "no-active",
      "to": "/about"
    }
  }, [_c('v-list-item-title', [_c('b', [_vm._v("회사소개")])])], 1), _c('v-divider'), _c('v-list-item', {
    staticClass: "white",
    attrs: {
      "active-class": "no-active",
      "to": "/board/question"
    }
  }, [_c('v-list-item-title', [_c('b', [_vm._v("문의하기")])])], 1), _c('v-divider'), _c('v-list-group', {
    staticClass: "white green-menu__active"
  }, [_c('v-list-item-title', {
    attrs: {
      "slot": "activator"
    },
    slot: "activator"
  }, [_c('b', [_vm._v("고객센터")])]), _c('v-divider'), _c('v-list-item-group', [_c('v-list-item', {
    attrs: {
      "active-class": "white secondary--text",
      "to": "/center/notifications"
    }
  }, [_c('v-list-item-title', [_vm._v("공지사항")])], 1), _c('v-list-item', {
    attrs: {
      "active-class": "white secondary--text",
      "to": "/center/faqs"
    }
  }, [_c('v-list-item-title', [_vm._v("자주묻는질문")])], 1)], 1)], 1), _c('v-divider'), _c('v-list-item', {
    staticClass: "white",
    attrs: {
      "active-class": "no-active",
      "to": "/gshop/giftcards"
    }
  }, [_c('v-list-item-title', [_c('b', [_vm._v("기프트샵")])])], 1), _c('v-divider'), _vm.siteInfo.memberPayable && _vm.logon ? [_c('v-list-group', {
    staticClass: "white"
  }, [_c('v-list-item-title', {
    attrs: {
      "slot": "activator"
    },
    slot: "activator"
  }, [_c('b', [_vm._v("마이페이지")])]), _c('v-divider'), _c('v-list-item-group', [_c('v-list-item', {
    attrs: {
      "active-class": "no-active",
      "to": "/member/profile"
    }
  }, [_c('v-list-item-title', [_vm._v("회원정보수정")])], 1), _c('v-list-item', {
    attrs: {
      "active-class": "no-active",
      "to": "/member/gshop/orders"
    }
  }, [_c('v-list-item-title', [_vm._v("상품권 구매내역")])], 1)], 1)], 1), _c('v-divider')] : _vm._e(), _c('v-card', {
    staticClass: "pa-5",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-subtitle', {
    staticClass: "pa-0"
  }, [_c('span', {
    staticClass: "black--text"
  }, [_vm._v("대표전화")])]), _c('v-divider', {
    staticClass: "pa-0 my-2"
  }), _c('v-card-title', {
    staticClass: "pa-0"
  }, [_c('span', {
    staticClass: "mt-2 mr-1 subtitle-2 black--text"
  }, [_vm._v("Tel.")]), _c('span', {
    staticClass: "headline primary--text-green"
  }, [_vm._v("1533-8639")])]), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('span', {
    staticClass: "caption black--text"
  }, [_vm._v("평일 10:00 - 17:00 / 점심 12:30 - 13:30")])]), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('span', {
    staticClass: "caption black--text"
  }, [_vm._v("주말 및 공휴일 휴무")])])], 1), _vm.siteInfo.memberPayable ? [_c('v-divider'), _c('v-list-item', {
    staticClass: "justify-end"
  }, [_vm.logon ? [_c('v-list-item-action', {
    staticClass: "ma-0"
  }, [_c('v-btn', {
    attrs: {
      "plain": ""
    },
    on: {
      "click": _vm.logout
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "size": "16"
    }
  }, [_vm._v(_vm._s(_vm.mdiLogoutVariant))]), _vm._v("로그아웃")], 1)], 1)] : [_c('v-list-item-action', {
    staticClass: "ma-0"
  }, [_c('v-btn', {
    attrs: {
      "plain": "",
      "active-class": "no-active",
      "to": "/login"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "size": "16"
    }
  }, [_vm._v(_vm._s(_vm.mdiLockOutline))]), _vm._v("로그인")], 1)], 1), _c('v-list-item-action', {
    staticClass: "ma-0"
  }, [_c('v-btn', {
    attrs: {
      "plain": "",
      "active-class": "no-active",
      "to": "/join"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "size": "16"
    }
  }, [_vm._v(_vm._s(_vm.mdiAccountPlus))]), _vm._v("회원가입")], 1)], 1)]], 2)] : _vm._e()], 2)], 1), _c('v-main', [_c('v-container', {
    staticClass: "pa-0 overflow-visible",
    attrs: {
      "fluid": ""
    }
  }, [_vm._t("default")], 2)], 1), _c('v-divider'), _c('v-responsive', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _vm.onFooter,
        options: {
          threshold: [0, 1.0]
        }
      },
      expression: "{\n            handler: onFooter,\n            options: { threshold: [0, 1.0] },\n        }"
    }],
    attrs: {
      "min-height": "200"
    }
  }, [_c('v-footer', {
    staticClass: "body-2 overflow-auto footer-serve",
    class: {
      'justify-center': !_vm.$vuetify.breakpoint.mobile
    },
    attrs: {
      "color": "#ffffff",
      "height": "64"
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "auto",
      "max-width": _vm.maxWidth
    }
  }, [_c('v-row', {
    staticClass: "py-2",
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pr-4",
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/terms/tos"
    }
  }, [_vm._v("이용약관")])], 1), _c('v-divider', {
    staticClass: "my-1 grey lighten-2",
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', {
    staticClass: "px-4",
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/terms/privacy"
    }
  }, [_vm._v("개인정보취급방침")])], 1)], 1)], 1)], 1), _c('hr', {
    staticClass: "v-divider theme--light",
    attrs: {
      "data-v-3f0bee24": "",
      "role": "separator",
      "aria-orientation": "horizontal"
    }
  }), _c('v-footer', {
    staticClass: "justify-center",
    attrs: {
      "color": "white",
      "min-height": "73"
    }
  }, [_c('v-responsive', {
    staticClass: "py-6",
    class: {
      'pa-4': _vm.$vuetify.breakpoint.mobile
    },
    attrs: {
      "width": "100vw",
      "max-width": _vm.maxWidth
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mr-auto",
    attrs: {
      "cols": "12",
      "sm": "3",
      "lg": "3"
    }
  }, [_c('img', {
    staticStyle: {
      "display": "block"
    },
    attrs: {
      "src": "/images/ticketmania/footer-logo.png",
      "width": "164",
      "height": "30",
      "contain": ""
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "9",
      "lg": "9"
    }
  }, [(_vm$siteInfo = _vm.siteInfo) !== null && _vm$siteInfo !== void 0 && _vm$siteInfo.customFooterEnabled ? _c('custom-footer') : _c('ul', {
    staticClass: "footer-info"
  }, [_c('li', [_c('b', {
    staticClass: "mr-2"
  }, [_vm._v("회사명")]), _vm._v("티켓매니아")]), _c('li', [_c('b', {
    staticClass: "mr-2"
  }, [_vm._v("주소")]), _vm._v("울산광역시 남구 문수로423번길 4-33, 1동 301호(신정동)")]), _c('li', [_c('b', {
    staticClass: "mr-2"
  }, [_vm._v("대표번호")]), _vm._v("1533-8639")]), _c('li', [_c('b', {
    staticClass: "mr-2"
  }, [_vm._v("대표자")]), _vm._v("이석준")]), _c('li', [_c('b', {
    staticClass: "mr-2"
  }, [_vm._v("대표핸드폰번호")]), _vm._v("010-9408-8300")]), _c('li', [_c('b', {
    staticClass: "mr-2"
  }, [_vm._v("사업자등록번호")]), _vm._v("781-97-01414")]), _c('li', [_c('b', {
    staticClass: "mr-2"
  }, [_vm._v("통신판매")]), _vm._v("제2021-울산남구-0020호")])])], 1)], 1)], 1)], 1)], 1), _vm._t("fab", null, {
    "scrollTo": _vm.scrollTo,
    "showScroll": _vm.showScroll
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }